document.addEventListener('DOMContentLoaded', function(){
	const gdprBlock = document.getElementById('header-gdpr');
	const gdprOk = document.getElementById('header-gdpr-in-btn');
	const gdprClose = document.getElementById('header-gdpr-in-close');
	var cookieif = document.cookie.indexOf('gdprCookieData');
	if(cookieif == -1) {
		gdprBlock.classList.add('is-active');
	}
	gdprOk.addEventListener('click', function(){
		gdprBlock.classList.remove('is-active');
		var now = new Date();
		now.setDate(now.getDate() + 90);
		document.cookie = 'gdprCookieData=ok;expires=' + now.toUTCString()+';path=/;';
	});
	gdprClose.addEventListener('click', function(){
		gdprBlock.classList.remove('is-active');
	});
});